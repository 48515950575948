import {useEffect, useState} from 'react'

import {industryFilter} from 'helpers/filters/insights/industryFilter'
import {retrieveDefaultInsights} from 'helpers/filters/insights/retrieveDefaultInsights'
import {serviceFilter} from 'helpers/filters/insights/serviceFilter'
import {typeFilter} from 'helpers/filters/insights/typeFilter'

import useIsBrowser from 'hooks/useIsBrowser'

const url = `https://graphql.contentful.com/content/v1/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/?access_token=${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}`

const request = graphqlQuery => ({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    query: graphqlQuery,
  }),
})

const contentfulGraphQlFiltering = async (props, hook) => {
  const {
    countryItemsIds,
    countryInsights,
    nodeLocale,
    currentTag,
    typesTags,
    industriesTags,
    services,
  } = props

  const hasTypes =
    currentTag &&
    typesTags.filter(value => currentTag.includes(value)).length !== 0
  const hasIndustries =
    currentTag &&
    industriesTags.filter(value => currentTag.includes(value)).length !== 0

  const hasServices =
    currentTag &&
    services.filter(value => currentTag.includes(value)).length !== 0

  if (hasTypes && !hasIndustries) {
    typeFilter(url, request, hook, nodeLocale, countryItemsIds, currentTag)
  }
  if (hasIndustries && !hasTypes) {
    industryFilter(url, request, nodeLocale, currentTag, hook, countryInsights)
  }
  if (hasServices && !hasTypes && !hasIndustries) {
    serviceFilter(url, request, nodeLocale, currentTag, hook, countryInsights)
  }
}

const useGraphQLFiltersInsights = (
  countryItemsIds,
  countryInsights,
  pageContext,
  countryData,
  currentTag,
  typesTags,
  industriesTags,
  services,
) => {
  const [itemsToRender, setItemsToRender] = useState([])

  const {nodeLocale} = pageContext
  const {urlPrefix} = countryData

  const props = {
    countryItemsIds,
    countryInsights,
    urlPrefix,
    nodeLocale,
    currentTag,
    typesTags,
    industriesTags,
    services,
  }

  const noFilterSelected =
    useIsBrowser &&
    (window.location.hash === '' ||
      window.location.hash === '#/' ||
      currentTag.length === 0)

  useEffect(() => {
    if (noFilterSelected) {
      retrieveDefaultInsights(
        url,
        request,
        setItemsToRender,
        nodeLocale,
        countryItemsIds,
      )
    } else {
      setTimeout(() => {
        contentfulGraphQlFiltering(props, setItemsToRender)
      }, 100)
    }
  }, [useIsBrowser && window.location.hash && currentTag])

  return itemsToRender
}

export default useGraphQLFiltersInsights
